$(window).scroll(function () {
    var pos = $(window).scrollTop();
    if (pos > 0) {
        $('.navbar').addClass('nav-on-scroll');
    }
    if (pos == 0) {
        $('.navbar').removeClass('nav-on-scroll');
    }
});
$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();

    $('.product-slider').bxSlider({
        controls: false
    });
    $('.hero-banner-slider').bxSlider({
        controls: false
    });
    var slider = $('.quote-slider').bxSlider({
        pagerCustom: '#testi-bx-pager',
        controls: false
    });

    $('a.pager-prev').click(function () {
        var current = slider.getCurrentSlide();
        slider.goToPrevSlide(current) - 1;
    });
    $('a.pager-next').click(function () {
        var current = slider.getCurrentS
        slide();
        slider.goToNextSlide(current) + 1;
    });
});

//Scrolling Nav
(function($) {
    "use strict"; // Start of use strict

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 54)
                }, 1000, "easeInOutExpo");
                return false;
            }
        }
    });
})(jQuery); // End of use strict

